import React from 'react';
import QRCode from 'react-qr-code';


const GenerateQRCode = () => {

  console.log('GenerateQRCode called');
  const urlParams = new URLSearchParams(window.location.search);

  console.log("URL Params:", urlParams);
  console.log("Staff Lesson ID:", urlParams.get('staffLessonId'));
  console.log("Classroom ID:", urlParams.get('classroomId'));
  console.log("Staff ID:", urlParams.get('staffId')); 

  // Extract required data for QR code
  const qrData = {
    staffLessonId: urlParams.get('staffLessonId') || 0,
    classroomId: urlParams.get('classroomId') || 0,
    staffId: urlParams.get('staffId') || 0,
    subjectId: urlParams.get('subjectId')  || 0,
    subjectSubId: urlParams.get('subjectSubId')  || 0,
    chapterId: urlParams.get('chapterId') || 0,
    academicYearId: urlParams.get('academicYearId') || 0,
};


console.log("QR Data:", qrData);

  // Convert data to JSON string for QR code
  const qrContent = JSON.stringify(qrData);

  return (
    <div className="flex flex-col items-center p-4">
      <QRCode 
        value={qrContent}
        size={500}
        level="H"
        
      />
      <p className="mt-2 text-sm text-gray-600">Scan to view lesson details</p>
    </div>
  );
};

export default GenerateQRCode
import React, { useState, useEffect } from 'react';
import './RandomRecall.css';
import {
  Button 
} from "@mui/material";
import { useNavigate } from 'react-router-dom'; 

const dummyQuestions = [
  {
    subject: "Mathematics",
    question: "What is the square root of 144?",
    answer: "12"
  },
  {
    subject: "Science",
    question: "Which planet is known as the Red Planet?",
    answer: "Mars"
  },
  {
    subject: "Chemistry",
    question: "What is the chemical symbol for Gold?",
    answer: "Au"
  },
  {
    subject: "Geography",
    question: "What is the capital city of Japan?",
    answer: "Tokyo"
  },
  {
    subject: "Physics",
    question: "What is the unit of electric current?",
    answer: "Ampere"
  },
  {
    subject: "Biology",
    question: "What is the hardest substance in the human body?",
    answer: "Tooth Enamel"
  },
  {
    subject: "History",
    question: "In which year did World War II end?",
    answer: "1945"
  },
  {
    subject: "Mathematics",
    question: "What is 7 x 8?",
    answer: "56"
  },
  {
    subject: "Science",
    question: "What is the largest organ in human body?",
    answer: "Skin"
  },
  {
    subject: "Chemistry",
    question: "What is H2O?",
    answer: "Water"
  }
];

const StudentPicker = () => {
  const [number, setNumber] = useState<number | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [showNumber, setShowNumber] = useState(false);
  const [rollingNumber, setRollingNumber] = useState<number | null>(null);
  const [currentQuestion, setCurrentQuestion] = useState<any>(null);
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(true);
  const [totalStudents, setTotalStudents] = useState(40);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchQuestion = async () => {
      setIsLoadingQuestion(true);
      await new Promise(resolve => setTimeout(resolve, 1000));
      const randomIndex = Math.floor(Math.random() * dummyQuestions.length);
      setCurrentQuestion(dummyQuestions[randomIndex]);
      setIsLoadingQuestion(false);
    };

    fetchQuestion();
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isGenerating) {
      intervalId = setInterval(() => {
        setRollingNumber(Math.floor(Math.random() * totalStudents) + 1);
      }, 100);

      setTimeout(() => {
        clearInterval(intervalId);
        const finalNumber = Math.floor(Math.random() * totalStudents) + 1;
        setNumber(finalNumber);
        setIsGenerating(false);
        setShowNumber(true);
        setShowAnimation(true);
        setRollingNumber(null);
        
        setTimeout(() => {
          setShowAnimation(false);
          setShowNumber(false);
          setNumber(null);
        }, 10000);
      }, 15000);
    }
    return () => clearInterval(intervalId);
  }, [isGenerating, totalStudents]);

  const handleGenerate = () => {
    setIsGenerating(true);
    setNumber(null);
    setShowNumber(false);
    setShowAnimation(false);
  };

  const handleShowAnswer = () => {
    setShowAnswer(true);
    setTimeout(() => {
      setShowAnswer(false);
    }, 2000);
  };

  const handleNextQuestion = async () => {
    setIsLoadingQuestion(true);
    setShowNumber(false);
    setShowAnswer(false);
    await new Promise(resolve => setTimeout(resolve, 1000));
    const randomIndex = Math.floor(Math.random() * dummyQuestions.length);
    setCurrentQuestion(dummyQuestions[randomIndex]);
    setIsLoadingQuestion(false);
  };

  return (
    <div className="main-container">
      <Button
        color="secondary"
        onClick={() => navigate(-1)}
        style={{ position: 'fixed', bottom: 40, right: 40, zIndex: 1000, color:"white" }}
      >
        Go Back
      </Button>
      {/* <div className="card">
        <div className="card-content">
          {isLoadingQuestion ? (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          ) : (
            <div className="question-container">
              <div className="subject-tag">{currentQuestion?.subject}</div>
              {!showAnswer ? (
                <>
                  <div className="question-box">
                    <h2 className="question-text">
                      {currentQuestion?.question}
                    </h2>
                  </div>
                  <div className="button-row">
                    <button onClick={handleShowAnswer} className="show-answer-button">
                      Show Answer
                    </button>
                    <button onClick={handleNextQuestion} className="next-button">
                      Next Question
                    </button>
                  </div>
                </>
              ) : (
                <div className="answer-box">
                  <h2 className="answer-text">
                    {currentQuestion?.answer}
                  </h2>
                </div>
              )}
            </div>
          )}
        </div>
      </div> */}

      <div className="number-generator-container">
        {!showNumber && !isGenerating && (
          <button
            onClick={handleGenerate}
            className="pick-student-button"
            disabled={isGenerating}
          >
            Pick Student
          </button>
        )}

        {(isGenerating || showNumber) && (
          <div className="circle-container">
            {isGenerating && (
              <svg className="dashed-circle" viewBox="0 0 100 100">
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  className="outer-circle-path"
                />
              </svg>
            )}

            <svg className="outer-circle" viewBox="0 0 100 100">
              {isGenerating && (
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  className="inner-circle-path"
                >
                  <animate
                    attributeName="r"
                    values="42;44;42"
                    dur="1.5s"
                    repeatCount="indefinite"
                  />
                  <animate
                    attributeName="opacity"
                    values="0.6;0.8;0.6"
                    dur="1.5s"
                    repeatCount="indefinite"
                  />
                </circle>
              )}

              {showNumber && (
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  className="fill-circle"
                  strokeDasharray="285"
                  strokeLinecap="round"
                >
                  <animate
                    attributeName="stroke-dashoffset"
                    from="285"
                    to="0"
                    dur="2s"
                    fill="freeze"
                  />
                </circle>
              )}
            </svg>

            <div className="number-display">
              <span className={showAnimation ? 'number-zoom' : ''}>
                {isGenerating ? rollingNumber : number}
              </span>
              {showNumber && (
                <div className={`roll-completed ${showAnimation ? 'text-zoom' : ''}`}>
                  Roll Number
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentPicker; 
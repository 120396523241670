import React, { useState, useEffect } from "react";
import {
  Box,
} from "@mui/material";
import GenerateQRCode from "../../GenerateQRCode";
import { useLocation } from 'react-router-dom';

interface QuizIntProps {
  onComplete?: () => void;
  onShowQuiz: () => void;
  lesson?: any; // This should match the 'plan' prop type from QuizComponent
}

const QuizInt: React.FC<QuizIntProps> = ({ onComplete, onShowQuiz, lesson }) => {
  console.log("QuizInt called");
  const location = useLocation();
  console.log("QuizInt called");
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: 'white',
        padding: 4,
      }}
    >
      <Box
        component="img"
        src="assets/pop_quiz_logo.png"
        alt="Quiz"
        sx={{
          width: 1000,
          height: 300,
          animation: 'pulse 2s infinite',
          '@keyframes pulse': {
            '0%': {
              transform: 'scale(1)',
            },
            '50%': {
              transform: 'scale(1.1)',
            },
            '100%': {
              transform: 'scale(1)',
            },
          },
        }}
      />

      <GenerateQRCode />  
    </Box>
  );
};
  
export default QuizInt;
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { registerSW } from "virtual:pwa-register";
import "../excalidraw-app/sentry";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Dashboard from "./src/dashboard-page";
// import { Attendance } from "./src/teacher-attendance-page";
import ExcalidrawApp from "./App";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "./src/Login";
import WhiteboardWithSlides from "./src/modules/whiteBoard/Swiper";
import Quiz from "./src/modules/popQuiz/Quiz";


import ClassroomPicker from "./src/frags/RandomRecall";
import FullScreenTimer from "./src/frags/FullScreenTimer";

// window.__EXCALIDRAW_SHA__ = import.meta.env.VITE_APP_GIT_SHA;
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

// // methods for timer
function handleTimerEnd () {
  // Handle what happens when the timer ends
  console.log("Time's up!");
};


registerSW();
root.render(
  <StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/" element={<Dashboard />} /> */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/dashboard-page" element={<Dashboard />} />  */}
        {/* <Route path="/teacher-attendance-page" element={<Attendance />} /> */}
        <Route path="/whiteboard" element={<ExcalidrawApp />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/swiper" element={<WhiteboardWithSlides />} />

        {/* <Route path="/quiz" element={<Quiz />} /> */}

        <Route path="/dice" element={<ClassroomPicker />} />
       
        <Route
          path="/timer"
          element={<FullScreenTimer onTimerEnd={handleTimerEnd} />}
        />
      </Routes>
    </BrowserRouter>
  </StrictMode>,
);



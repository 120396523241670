import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import Confetti from "react-confetti";
import "./Quiz.css";
import QuizComponent from "./QuizComponent";
import StudentLeaderboard from "./Leaderboard";
import { useNavigate, useLocation } from 'react-router-dom'; 
import LessonQRCode from "../../LessonQRCode";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import QuizInt from "./QuizInt";

interface QuizProps {
  plan: any; // You can define a more specific type based on your plan structure
}

const PodiumPlatform: React.FC<{ position: number }> = ({ position }) => (
  <Box
    sx={{
      width: 220,
      height: position === 1 ? 400 : 300,
      backgroundColor: "grey.300",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: -10,
      marginX: 1,
      position: "relative",
    }}
  >
    <Typography variant="h3" sx={{ color: "black", fontWeight: "bold", marginBottom: 1 }}>
      {position}
    </Typography>

    <Typography variant="body1" sx={{ color: "gray", fontWeight: "bold", textAlign: "center" }}>
      {position === 1 ? "13 Questions Answered Correct" : position === 2 ? "10 Questions Answered Correct" : "09 Questions Answered Correct"}
    </Typography>

    <Box
      component="img"
      src={
        position === 1
          ? "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fimages%20(6).jfif?alt=media&token=debbe6e2-26a4-41e1-9cc5-ccde771eb6a2"
          : position === 2
          ? "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fishita-niwas-gn-ii.webp?alt=media&token=52a5e607-a44a-41ae-8c12-d40bc2c7bcb2"
          : "https://firebasestorage.googleapis.com/v0/b/classess-501e6.appspot.com/o/images%2Fdownload%20(5).jfif?alt=media&token=6dc01496-1c8e-44fc-81a4-ec7688085a9b"
      }
      alt={`Position ${position}`}
      sx={{ width: 100, height: 100, borderRadius: "50%", border: "2px solid grey", objectFit: "cover", marginTop: -32 }}
    />

    <Typography variant="h6" sx={{ color: "black", fontWeight: "bold", textAlign: "center", marginTop: 1 }}>
      {position === 1 ? "Abu" : position === 2 ? "Suhasini" : "Jyo"}
    </Typography>
  </Box>
);

const Podium: React.FC = () => {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showConfetti && <Confetti />}
      <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "center", height: "auto", position: "relative", paddingTop: 20 }}>
        <PodiumPlatform position={2} />
        <PodiumPlatform position={1} />
        <PodiumPlatform position={3} />
      </Box>
    </>
  );
};

const Quiz: React.FC<{plan?: any}> = ({ plan: propPlan }) => {
  console.log("Quiz called");
  const location = useLocation();
  const hasParams = location.search.length > 0;
  
  const [loading, setLoading] = useState(true);
  const [showPodium, setShowPodium] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [isQuizOpen, setIsQuizOpen] = useState(location.pathname === "/quiz");
  const [quizIntComplete, setQuizIntComplete] = useState(false);
  const navigate = useNavigate(); 
  const plan = location.state?.plan || propPlan;
  console.log("Plan:", plan);
  // The following line specifically handles direct URL access with parameters
  // It will auto-open the quiz if parameters are detected in the URL
  useEffect(() => {
    if (location.pathname === "/quiz") {
      setIsQuizOpen(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/quiz") {
      setIsQuizOpen(true);
    } else {
      setIsQuizOpen(false);
    }
  }, [location.pathname]);


  const isPlanAvailable = plan && plan.data && plan.data.length > 0;

  const handleShowPodium = () => {
    setShowQuiz(false);
    setQuizIntComplete(false);
    setShowPodium(true);
  };

  const handleQuizClick = () => {
    if (plan) {
      navigate("/quiz", { state: { plan } }); // Pass plan when navigating
    }
  };

  const handleCloseQuiz = () => {
    navigate("/whiteboard"); // Go back to whiteboard
  };

 
  if (location.pathname !== "/quiz") {
    // If we are NOT on /quiz, render only the quiz button
    return (
      <Box onClick={handleQuizClick}>
        <QuizOutlinedIcon
          style={{color: isPlanAvailable ? "rgb(10,10,10)" :  "#ccc", fontSize: "17px", marginBottom: "10px" }}
        />
        
      </Box>
    );
  }

  const handleQuizIntComplete = () => {
    setQuizIntComplete(true);
  };

  /*<Button
          variant="contained"
          style={{ backgroundColor: "black", color: "white" }}
        >
          Open Quiz
        </Button>*/
  // Simple component rendering, just show the icon if quiz is not open
  if (!isQuizOpen) {
    return (
      <Box onClick={handleQuizClick}>
        <QuizOutlinedIcon
          style={{color: isPlanAvailable ? "rgb(10,10,10)" :  "#ccc", fontSize: "17px", marginBottom: "10px" }}
        />
      </Box>
    );
  } 

 /* if (!isQuizOpen) {
    return null; // If quiz is not open, return nothing
  } */

  // Full quiz view when open
  console.log("Show Podium:", showPodium);
  console.log("Show Quiz:", showQuiz);
  console.log("Quiz Int Complete:", quizIntComplete);
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        zIndex: 1000,
        overflow: "hidden",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <Button
        color="secondary"
        onClick={handleCloseQuiz}
        style={{ position: 'fixed', bottom: 40, right: 40, zIndex: 1000 }}
      >
        End Quiz
      </Button>
      
      
      {/* Show intro components based on conditions */}
      {!showQuiz && !showPodium && !hasParams && (
        <QuizIntro 
          lesson={plan} 
          onShowQuiz={() => setShowQuiz(true)} 
        />
      )}
      
      {/* Show QuizInt if there are query params and it's not completed yet */}
      {hasParams && !quizIntComplete &&  !showPodium &&  (
        <QuizInt 
          lesson={plan}
          onComplete={handleQuizIntComplete} 
          onShowQuiz={() => 
            {setShowQuiz(true); setQuizIntComplete(true)} } 
        />
      )}
      
     {/* && (showQuiz || quizIntComplete) */}
      {/* Show the quiz component when showQuiz is true or QuizInt is complete */}
      {!showPodium  && (
        <QuizComponent 
          lesson={plan} 
          onShowQuiz={() => {setShowQuiz(true);
            setQuizIntComplete(true)} 
          }
          onShowPodium = {()=> setShowPodium(true)}

        />
      )}
      
      {/* Show leaderboard when showPodium is true */}
      {showPodium && <StudentLeaderboard lesson={plan} />}
    </Box>
  );
};

export default Quiz;

const QuizIntro = ({ lesson, onShowQuiz }) => {
  console.log("QuizIntro called");
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        color: 'white',
        padding: 4,
      }}
    >
      <Box
        component="img"
        src="assets/pop_quiz_logo.png"
        alt="Quiz"
        sx={{
          width: 1000,
          height: 300,
          animation: 'pulse 2s infinite',
          '@keyframes pulse': {
            '0%': {
              transform: 'scale(1)',
            },
            '50%': {
              transform: 'scale(1.1)',
            },
            '100%': {
              transform: 'scale(1)',
            },
          },
        }}
      />

      <LessonQRCode lesson={lesson} />  
    </Box>
  );
};
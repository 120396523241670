/* eslint-disable no-console */
// HTTP methods for API requests

import axios from "axios";
import Constants from "../util/Constants";
export default {
  async getDefaultClassrooms(instId: number) {
    const resp = await fetch(
      `${Constants.SPRING_ENDPOINT}/institute/get-institute-classrooms/${instId}`,
    );

    const resp2 = await resp.json();

    if (resp2) {
      return resp2;
    }
    return false;
  },

  anotherFunc() {
    // More API requests...
  },

  async getClassroomLessons1(clrId: number) {
    const resp = await fetch(
      `${Constants.SPRING_ENDPOINT}/institute/get-classroom-upcoming-lessons/${clrId}`,
    );
    const resp2 = await resp.json();

    if (resp2) {
      return resp2;
    }
    return false;
  },

  async getClassroomLessons(clrId: number) {
    const resp = await fetch(
      `${Constants.SPRING_ENDPOINT}/institute/get-institute-classroom-lessons/${clrId}`,
    );
    const resp2 = await resp.json();
    
    localStorage.setItem('classroomId', JSON.stringify(clrId));
    console.log("Stored Classroom ID:", localStorage.getItem('classroomId'));

    if (resp2) {
      return resp2;
    }
    return false;
  },

  // get publisher
  async getPublisher(
    subId: any,
    subSubId: any,
    clsStd: any,
    instId: any,
    yearId: any,
  ) {
    const URL = `${Constants.PLATFORM_ENDPOINT}/publishers/?institute_id=${instId}&classroom_std=${clsStd}&sub_id=${subId}&sub_sub_id=${subSubId}&aca_year_id=${yearId}`;
    console.log("getPublisher() URL", URL);
    let pubId = 0;

    try {
      const resp = await fetch(URL);

      const resp2 = await resp.json();
      console.log("getPublisher() resp2", resp2);

      if (resp2 && resp2.data) {
        pubId = resp2.data.partner_publisher_id;
        // pubBookId = pubId;
      }
    } catch (e) {
      // TODO: handle exception
      console.error("getPublisher() exception ", e);
    }

    const pubId3 = -parseInt(instId);
    if (pubId <= 0) {
      pubId = pubId3;
    } // below function uses pubId, not pubId3

    return pubId;
  },

  async getCurrentLesson2(
    staffId: any,
    clrId: any,
    subId: any,
    subSubId: any,
    instId: any,
    pubId: any,
  ) {
    const URL1 = `${Constants.PLATFORM_ENDPOINT}/get-current-course-lesson-plan/?staff_id=${staffId}&cls_id=${clrId}&subject_id=${subId}&subject_sub_id=${subSubId}&institute_id=${instId}&pub_id=${pubId}`;
    const resp = await fetch(URL1);
    const resp2 = await resp.json();

    console.log("getCurrentLesson2() resp2", resp2);

    if (resp2) {
      return resp2;
    }
    return false;
  },

  // More API requests...
  // async postTeacherCreateMeeting(
  //   goVar: any,
  //   isLive: any,
  //   cCLsnId: any,
  //   stfLsnId: any,
  //   chpId: any,
  //   topId: any,
  //   staffId: any,
  //   subId: any,
  //   subSubId: any,
  //   clsStd: any,
  //   instId: any,
  //   clrId: any,
  //   oPlanId: any,

    

  // ) {
  //   const dte = new Date();
  //   const dtStr = `${dte.toISOString().split(".")[0]}Z`;

  //   try {

  //     // const URL = `${Constants.SITE_URL}/schooler/teacher-create-meeting`;
  //     // const data3 = `classroomid=${clrId}&subid=${subSubId}&slessonid=${stfLsnId}&chpid=${chpId}&topicid=${topId}&staffid=${staffId}&iframe=false&restreq=true&guruc=true&lsnid=${cCLsnId}&lsnplanid=${oPlanId}&datetime=${dtStr}&datetime2=${dtStr}`;
      
  //     const URL = `${Constants.SPRING_ENDPOINT}/teacher/create-teacher-meeting`;
  //     const data3 = `classroom_id=${clrId}&subject_id=${subSubId}&slesson_id=${stfLsnId}&chapter_id=${chpId}&topic_id=${topId}&staff_id=${staffId}&iframe=false&restreq=true&guruc=true&lsnid=${cCLsnId}&lsnplanid=${oPlanId}&datetime=${dtStr}&datetime2=${dtStr}`;

  //     const URL1 = `${URL}?${data3}`;
  //     console.log("postTeacherCreateMeeting() URL1", URL1);

  //     const resp = await fetch(URL1);
  //     const resp2 = await resp.json();

  //     if (resp2) {
  //       return resp2;
  //     } else {
  //       return false;
  //     }



  //   } catch (error) {
  //     console.error("postTeacherCreateMeeting() error", error);
  //     return false;
  //   }

  // },

  async postTeacherCreateMeeting(
    goVar: any,
    isLive: any,
    cCLsnId: any,
    stfLsnId: any,
    chpId: any,
    topId: any,
    staffId: any,
    subId: any,
    subSubId: any,
    clsStd: any,
    instId: any,
    clrId: any,
    oPlanId: any,
  ) {
    const dte = new Date();
    const dtStr = `${dte.toISOString().split(".")[0]}Z`;

    try {

      const URL = `${Constants.SPRING_ENDPOINT}/teacher/create-teacher-meeting`;
      // const data3 = `classroom_id=${clrId}&subject_id=${subSubId}&slesson_id=${stfLsnId}&chapter_id=${chpId}&topic_id=${topId}&staff_id=${staffId}&iframe=false&restreq=true&guruc=true&lsnid=${cCLsnId}&lsnplanid=${oPlanId}&datetime=${dtStr}&datetime2=${dtStr}`;

      // const URL1 = `${URL}?${data3}`;
      // console.log("postTeacherCreateMeeting() URL1", URL1);

      // const resp = await fetch(URL1);
      // const resp2 = await resp.json();

      // if (resp2) {
      //   return resp2;
      // } else {
      //   return false;
      // }

      const requestBody = {
        staff_id: staffId,
        is_publish: 1,
        classroom_id: clrId,
        subject_id: subSubId,
        subject_sub_id: subSubId, 
        chapter_id: chpId,
        topic_id: topId,
        lesson_id: cCLsnId,
        slesson_id: stfLsnId || 0
      };
  
      const response = await axios.post(URL, requestBody, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      return response.data;

    } catch (error) {
      console.error("postTeacherCreateMeeting() error", error);
      return false;
    }

  },


  // More API requests...
  async postTeacherJoinMeeting() {
    // const URL = `${Constants.SITE_URL}/teacher-join-meeting`;
  },
};

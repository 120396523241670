import React from 'react';
import QRCode from 'react-qr-code';


const LessonQRCode = ({ lesson }) => {

  console.log('LessonQRCode called with lesson:', lesson);
  const urlParams = new URLSearchParams(window.location.search);

  console.log("URL Params:", urlParams);
  console.log("Staff Lesson ID:", urlParams.get('staffLessonId'));
  console.log("Classroom ID:", urlParams.get('classroomId'));
  console.log("Staff ID:", urlParams.get('staffId')); 

  // Extract required data for QR code
  const qrData = {
    staffLessonId: urlParams.get('staffLessonId') || lesson?.data?.[0]?.chapter_id || 0,
    classroomId: urlParams.get('classroomId') || lesson?.data?.[0]?.icc_tracking?.classroom_id || 0,
    staffId: urlParams.get('staffId') || lesson?.data?.[0]?.staff_id || 0,
    subjectId: urlParams.get('subjectId') || lesson?.data?.[0]?.subject_id || 0,
    subjectSubId: urlParams.get('subjectSubId') || lesson?.data?.[0]?.subject_sub_id || 0,
    chapterId: urlParams.get('chapterId') || lesson?.data?.[0]?.icc_day || 0,
    academicYearId: urlParams.get('academicYearId') || lesson?.data?.[0]?.institute_academic_year_id || 0,
};

console.log("Lesson object:", lesson);
console.log("QR Data:", qrData);

  // Convert data to JSON string for QR code
  const qrContent = JSON.stringify(qrData);

  return (
    <div className="flex flex-col items-center p-4">
      <QRCode 
        value={qrContent}
        size={500}
        level="H"
        
      />
      <p className="mt-2 text-sm text-gray-600">Scan to view lesson details</p>
    </div>
  );
};

export default LessonQRCode